import React from 'react';
import PropTypes from 'prop-types';

const HeaderHamburger = ({ email, onSignOut }) => (
  <div className='header__hamburger'>
    <p className='header__email' aria-label='Адрес электронной почты текущего пользователя'>
      {email}
    </p>
    <button
      type='button'
      className='header__button'
      onClick={onSignOut}
      aria-label='Выйти из системы'>
      Выйти
    </button>
  </div>
);

HeaderHamburger.propTypes = {
  email: PropTypes.string.isRequired,
  onSignOut: PropTypes.func.isRequired,
};

export default HeaderHamburger;
