import React, {
  useState, useEffect, useContext,
} from 'react';

import PropTypes from 'prop-types';

import currentUserContext from '../contexts/currentUserContext';

function SignIn({ onSignInSubmit }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setEmailValidity] = useState(true);
  const [isPasswordValid, setPasswordValidity] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isFormValid, setFormValidityState] = useState(false);

  const emailInputErrorClass = !isEmailValid ? 'credentials__input_invalid' : '';
  const passwordInputErrorClass = !isPasswordValid ? 'credentials__input_invalid' : '';
  const user = useContext(currentUserContext);
  const { isLoggedIn } = user;

  useEffect(() => {
    if (!isLoggedIn) {
      setEmail('');
      setPassword('');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setFormValidityState(isEmailValid
    && isPasswordValid
    && email.length > 0
    && password.length > 0);
  }, [isEmailValid, isPasswordValid, email, password]);

  function handleEmailChange(event) {
    const input = event.target;
    setEmail(input.value);
    setEmailValidity(input.validity.valid);
    if (!input.validity.valid) {
      setEmailError(input.validationMessage);
    } else {
      setEmailError(input.validationMessage);
    }
  }

  function handlePasswordChange(event) {
    const input = event.target;
    setPassword(input.value);
    setPasswordValidity(input.validity.valid);
    if (!input.validity.valid) {
      setPasswordError(input.validationMessage);
    } else {
      setPasswordError(input.validationMessage);
    }
  }

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onSignInSubmit(email, password);
  }

  return (
    <main className='wrapper' aria-hidden='true'>
      <form
        name='SignInForm'
        className='credentials__form'
        aria-label='Форма входа в систему'>
        <h2 className='credentials__heading'>Вход</h2>
        <fieldset className='credentials__inputs'>
          <input
            id='signin-email-input'
            name='email'
            type='email'
            className={`credentials__input ${emailInputErrorClass}`}
            required
            minLength='2'
            maxLength='200'
            value={email}
            onChange={handleEmailChange}
            placeholder='E-mail'
            aria-label='Поле ввода адреса электронной почты' />
          <span
            id='signin-email-input-error'
            className='credentials__error-message'>
            {emailError}
          </span>
          <input
            id='signin-password-input'
            name='password'
            type='password'
            className={`credentials__input ${passwordInputErrorClass}`}
            required
            minLength='2'
            maxLength='200'
            value={password}
            onChange={handlePasswordChange}
            placeholder='Пароль'
            aria-label='Поле ввода пароля' />
          <span
            id='signin-password-input-error'
            className='credentials__error-message'>
            {passwordError}
          </span>
        </fieldset>
        <button
          type='submit'
          className='credentials__submit'
          onClick={handleSubmit}
          disabled={!isFormValid}
          aria-label='Кнопка входа в систему'>
          Войти
        </button>
      </form>
    </main>
  );
}
SignIn.propTypes = {
  onSignInSubmit: PropTypes.func.isRequired,

};
export default SignIn;
