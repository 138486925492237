export default (item) => {
  const {
    _id, name, link, likes, owner,
  } = item;
  const likesArr = likes.map((like) => like._id);
  const result = {
    id: _id,
    ownerID: owner._id,
    place: name,
    url: link,
    likes: likesArr,
  };
  return result;
};
