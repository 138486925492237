import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import Header from './Header';
import Main from './Main';
import Footer from './Footer';

import ImagePopup from './ImagePopup';
import EditProfilePopup from './EditProfilePopup';
import EditAvatarPopup from './EditAvatarPopup';
import NewPlacePopup from './NewPlacePopup';
import ErrorPopup from './ErrorPopup';
import SuccessPopup from './SuccessPopup';
import ConfirmDeletePopup from './ConfirmDeletePopup';
import ConfirmNewPopup from './ConfirmNewPopup';
import ConfirmAvatarPopup from './ConfirmAvatarPopup';
import ConfirmProfilePopup from './ConfirmProfilePopup';
import ProtectedRoute from './ProtectedRoute';
import SignIn from './SignIn';
import SignUp from './SignUp';

import currentUserContext from '../contexts/currentUserContext';

import { mestoAPI } from '../helpers/api';
import processData from '../helpers/processData';
import prepareError from '../helpers/prepareError';

/**
 *
 */
function App() {
  const defaultCard = {
    place: '',
    url: '',
    id: '',
    likes: [],
    ownerID: '',
  };

  const defaultUser = {
    id: '',
    name: '',
    title: '',
    avatar: '',
    email: '',
    isLoggedIn: false,
  };

  const defaultErrorObject = { errorName: '', errorMessage: '' };
  const [isProfileOpen, setProfileState] = useState(false);
  const [isAvatarOpen, setAvatarState] = useState(false);
  const [isPlaceOpen, setPlaceState] = useState(false);
  const [isViewOpen, setViewState] = useState(false);
  const [isErrorOpen, setErrorState] = useState(false);
  const [isSuccessOpen, setSuccessState] = useState(false);
  const [isConfirmDeleteOpen, setConfirmDeleteState] = useState(false);
  const [isConfirmNewOpen, setConfirmNewState] = useState(false);
  const [isConfirmProfileOpen, setConfirmProfileState] = useState(false);
  const [isConfirmAvatarOpen, setConfirmAvatarState] = useState(false);

  const [errorObject, setError] = useState(defaultErrorObject);
  const [successMessage, setSuccessMessage] = useState('');

  const [currentUser, setCurrentUser] = useState(defaultUser);

  const [isCardsLoading, setCardsLoadingState] = useState(false);
  const [cardsSubstitutingMessage, setCardsSubstitutingMessage] = useState('');
  const [isCardsLoadingError, setCardsLoadingErrorState] = useState(false);

  const [isProfileLoading, setProfileLoadingState] = useState(false);
  const [profileSubstitutingMessage, setProfileSubstitutingMessage] = useState('');
  const [isProfileLoadingError, setProfileLoadingErrorState] = useState(false);

  const [isDeleteInProgress, setDeleteProgress] = useState(false);
  const [isNewCardInProgress, setNewCardProgress] = useState(false);
  const [isUpdateAvatarInProgress, setUpdateAvatarProgress] = useState(false);
  const [isUpdateProfileInProgress, setUpdateProfileProgress] = useState(false);

  const [selectedCard, selectCard] = useState(defaultCard);
  const [idDelCard, setDelCardId] = useState('');
  const [linkDelCard, setDelCardLink] = useState('');
  const [nameDelCard, setDelCardName] = useState('');
  const [linkNewCard, setNewCardLink] = useState('');
  const [nameNewCard, setNewCardName] = useState('');
  const [avatarUri, setAvatarUri] = useState('');
  const [profileName, setProfileName] = useState('');
  const [profileAbout, setProfileAbout] = useState('');

  const [cards, setCards] = useState([]);

  const history = useHistory();

  /**
   *
   */
  function handleErrorClose() {
    setErrorState(false);
    setError(defaultErrorObject);
  }
  function handleSuccessClose() {
    setSuccessState(false);
    setSuccessMessage('');
  }
  /**
   *
   */
  function handleCardDropConfirmClose() {
    setConfirmDeleteState(false);
    setDelCardId('');
    setDelCardName('');
    setDelCardLink('');
  }
  /**
   *
   */
  function handleNewCardConfirmClose() {
    setConfirmNewState(false);
    setNewCardName('');
    setNewCardLink('');
  }
  function handleAvatarConfirmClose() {
    setConfirmAvatarState(false);
    setAvatarUri('');
  }
  function handleProfileConfirmClose() {
    setConfirmProfileState(false);
    setProfileName('');
    setProfileAbout('');
  }
  /**
   * @param id
   * @param isLike
   */
  async function setLike(id, isLike) {
    try {
      const likePromise = mestoAPI.sendLikeToServer(id, isLike);
      const res = await likePromise;
      if (res.name && res.createdAt && res._id) {
        setCards(
          cards.map((item) => (item.id !== id ? item : processData(res))),
        );
      } else if (res.message) {
        throw prepareError('Ошибка сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка сервера',
          'Неизвестная ошибка',
        );
      }
    } catch (err) {
      if (err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось получить данные с сервера',

        });
      } else {
        setError({
          errorName: err.name,
          errorMessage: err.message,
        });
      }
      setErrorState(true);
    }
  }

  /**
   * @param id
   */
  async function dropCard(id) {
    setDeleteProgress(true);
    try {
      const dropPromise = mestoAPI.deleteCard(id);
      const res = await dropPromise;
      if (res._id && res._id === id) {
        setCards(cards.filter((item) => item.id !== id));
      } else if (res.message) {
        throw prepareError('Ошибка сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка сервера',
          'Неизвестная ошибка',
        );
      }
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось получить данные с сервера',

        });
      } else if (err.message) {
        setError({
          errorName: err.name,
          errorMessage: err.message,
        });
      } else {
        setError({
          errorName: err.name,
          errorMessage: 'Неизвестная ошибка',
        });
      }
      setErrorState(true);
    } finally {
      handleCardDropConfirmClose();
      setDeleteProgress(false);
    }
  }

  /**
   *
   */
  async function getCards() {
    setCardsLoadingErrorState(false);
    setCardsSubstitutingMessage('Загружаю карточки с сервера....');
    setCardsLoadingState(true);
    try {
      const cardsPromise = mestoAPI.getAllCards();
      const res = await cardsPromise;
      if (Array.isArray(res) && res.every((card) => card._id && card.createdAt)) {
        setCards(res.map(processData));
      } else if (res.message) {
        throw prepareError('Ошибка  сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка  сервера',
          'Неизвестная ошибка',
        );
      }
    } catch (err) {
      if (err.message.match(/(fetch)$/)) {
        setCardsSubstitutingMessage('Ошибка сети: не удалось загрузить карточки с сервера./nПроверьте соединение с Интернет и попробуйте снова :)');
      } else {
        setCardsSubstitutingMessage(`${err.name} : "${err.message}"`);
      }
      setCardsLoadingErrorState(true);
    } finally {
      setCardsLoadingState(false);
    }
  }

  /**
   * @param pendingName
   * @param pendingTitle
   * @param userData
   */
  async function setUserProfile(name, about) {
    setUpdateProfileProgress(true);
    try {
      const profilePromise = mestoAPI.setUser({ name, about });
      const res = await profilePromise;
      if (res._id && res.about && res.name) {
        setCurrentUser({
          ...currentUser,
          id: res._id,
          name: res.name,
          title: res.about,
          avatar: res.avatar,
        });
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
      handleProfileConfirmClose();
      setProfileState(false);
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось получить данные с сервера',

        });
      } else if (err.message) {
        setError({
          errorName: err.name,
          errorMessage: err.message,
        });
      } else {
        setError({
          errorName: err.name,
          errorMessage: 'Неизвестная ошибка',
        });
      }
      setErrorState(true);
    } finally {
      setUpdateProfileProgress(false);
    }
  }

  /**
   * @param pendingURL
   */
  async function setUserAvatar(pendingURL) {
    const userData = { avatar: pendingURL };
    setUpdateAvatarProgress(true);
    try {
      const avatarPromise = mestoAPI.setAvatar(userData);
      const res = await avatarPromise;
      if (res._id && res.avatar) {
        const {
          _id, name, about, avatar,
        } = res;
        setCurrentUser({
          ...currentUser,
          id: _id,
          name,
          title: about,
          avatar,
        });
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
      setConfirmAvatarState(false);
      setAvatarState(false);
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось получить данные с сервера',

        });
      } else if (err.message) {
        setError({
          errorName: err.name,
          errorMessage: err.message,
        });
      } else {
        setError({
          errorName: err.name,
          errorMessage: 'Неизвестная ошибка',
        });
      }
      setErrorState(true);
      setConfirmAvatarState(false);
    } finally {
      setUpdateAvatarProgress(false);
    }
  }
  /**
   * @param place
   * @param url
   * @param place.data
   * @param name
   * @param link
   */
  async function sendCard(name, link) {
    setNewCardProgress(true);
    try {
      const cardPromise = mestoAPI.setCard({ name, link });
      const res = await cardPromise;
      if (res._id && res.createdAt) {
        getCards();
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
      handleNewCardConfirmClose();
      setPlaceState(false);
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось получить данные с сервера',

        });
      } else if (err.message) {
        setError({
          errorName: err.name,
          errorMessage: err.message,
        });
      } else {
        setError({
          errorName: err.name,
          errorMessage: 'Неизвестная ошибка',
        });
      }
      setErrorState(true);
      handleNewCardConfirmClose();
    } finally {
      setNewCardProgress(false);
    }
  }
  /**
   *
   */
  async function getUserProfile() {
    setProfileLoadingErrorState(false);
    setProfileSubstitutingMessage('Проверяю учётные данные входа...');
    setProfileLoadingState(true);
    try {
      const profilePromise = mestoAPI.getUser();
      const res = await profilePromise;
      if (res._id && res.name && res.about && res.avatar) {
        const {
          _id, name, about, avatar,
        } = res;
        setCurrentUser({
          ...currentUser,
          id: _id,
          name,
          title: about,
          avatar,
        });
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
      setProfileSubstitutingMessage('');
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setProfileSubstitutingMessage('Ошибка сети: не удалось загрузить карточки с сервера./nПроверьте соединение с Интернет и попробуйте снова :)');
      } else if (err.message) {
        setProfileSubstitutingMessage(`${err.name} : "${err.message}"`);
      } else {
        setProfileSubstitutingMessage(`${err.name} : неизвестная ошибка`);
      }
      setProfileLoadingErrorState(true);
    } finally {
      setProfileLoadingState(false);
    }
  }

  async function checkCredentials(token) {
    setProfileSubstitutingMessage('Проверяю сохранённые учётные данные...');
    setProfileLoadingState(true);
    try {
      const jwtPromise = mestoAPI.testJWT(token);
      const res = await jwtPromise;
      if (res.email && res._id) {
        const {
          email,
          _id,
          name,
          about,
          avatar,
        } = res;
        mestoAPI.setToken(token);
        setCurrentUser({
          name,
          avatar,
          title: about,
          isLoggedIn: true,
          email,
          id: _id,
        });
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setProfileSubstitutingMessage('Ошибка сети: не удалось загрузить карточки с сервера./nПроверьте соединение с Интернет и попробуйте снова :)');
      } else if (err.message) {
        setProfileSubstitutingMessage(`${err.name} : "${err.message}"`);
      } else if (err.name !== 'NO_JWT_ERROR') {
        setProfileSubstitutingMessage(`${err.name} : неизвестная ошибка`);
      }
      setProfileLoadingErrorState(true);
    } finally {
      setProfileLoadingState(false);
    }
  }

  async function handleSingIn(email, password) {
    try {
      const signinPromise = mestoAPI.signin(email, password);
      const res = await signinPromise;
      if (res.token) {
        const jwt = res.token;
        localStorage.setItem('jwt-mesto', jwt);
        checkCredentials(jwt);
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось загрузить карточки с сервера./nПроверьте соединение с Интернет и попробуйте снова :)',
        });
      } else if (err.message) {
        setError({
          errorName: `${err.name}`,
          errorMessage: `${err.message}`,
        });
      } else if (err.name !== 'NO_JWT_ERROR') {
        setError({
          errorName: `${err.name}`,
          errorMessage: 'Неизвестная ошибка!',
        });
      }
      setErrorState(true);
    } finally {
      if (currentUser.isLoggedIn) {
        history.push('/');
      }
    }
  }

  function handleSingOut() {
    localStorage.removeItem('jwt-mesto');
    setCurrentUser({ ...currentUser, isLoggedIn: false });
  }
  async function handleSingUp(email, password) {
    try {
      const signupPromise = mestoAPI.signup(email, password);
      const res = await signupPromise;
      if (res.data && res.data.email === email) {
        setSuccessMessage('Вы успешно зарегистрировались!');
        setSuccessState(true);
        history.push('/signin');
      } else if (res.message) {
        throw prepareError('Ошибка получения данных с сервера', res.message);
      } else {
        throw prepareError(
          'Ошибка получения данных с сервера',
          'Неизвестная ошибка',
        );
      }
    } catch (err) {
      if (err.message && err.message.match(/(fetch)$/)) {
        setError({
          errorName: 'Ошибка сети',
          errorMessage: 'Не удалось загрузить карточки с сервера./nПроверьте соединение с Интернет и попробуйте снова :)',
        });
      } else if (err.message) {
        setError({
          errorName: `${err.name}`,
          errorMessage: `${err.message}`,
        });
      } else if (err.name !== 'NO_JWT_ERROR') {
        setError({
          errorName: `${err.name}`,
          errorMessage: 'Неизвестная ошибка!',
        });
      }
      setErrorState(true);
    }
  }

  useEffect(() => {
    if (currentUser.isLoggedIn) {
      getUserProfile();
      getCards();
      history.push('/');
    } else {
      setCurrentUser(defaultUser);
      setCards([]);
      history.push('/signin');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem('jwt-mesto');
    if (token) checkCredentials(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  function openProfile() {
    setProfileState(true);
  }
  /**
   *
   */
  function openAvatar() {
    setAvatarState(true);
  }
  /**
   *
   */
  function openPlace() {
    setPlaceState(true);
  }

  /**
   * @param card
   */
  function openView(card) {
    selectCard(card);
    setViewState(true);
  }
  /**
   *
   */
  function closeView() {
    setViewState(false);
  }

  /**
   * @param name
   * @param title
   */
  function handleProfileSubmit(name, title) {
    setProfileName(name);
    setProfileAbout(title);
    setConfirmProfileState(true);
  }

  /**
   * @param url
   */
  function handleAvatarSubmit(url) {
    setAvatarUri(url);
    setConfirmAvatarState(true);
  }

  /**
   *
   */
  function reloadCards() {
    getCards();
  }

  /**
   * @param id
   * @param isLike
   */
  function handleCardLike(id, isLike) {
    setLike(id, isLike);
  }

  /**
   * @param id
   * @param name
   * @param link
   */
  function handleCardDropSubmit(id, name, link) {
    setDelCardId(id);
    setDelCardName(name);
    setDelCardLink(link);
    setConfirmDeleteState(true);
  }
  function handleProfileConfirm(name, about) {
    setUserProfile(name, about);
  }
  /**
   * @param id
   */
  function handleCardDropConfirm(id) {
    dropCard(id);
  }

  function handleAvatarConfirm(url) {
    setUserAvatar(url);
  }
  /**
   * @param name
   * @param link
   */
  function handleNewPlaceSubmit(name, link) {
    setNewCardName(name);
    setNewCardLink(link);
    setConfirmNewState(true);
  }

  /**
   * @param name
   * @param link
   */
  function handleNewCardConfirm(name, link) {
    sendCard(name, link);
  }

  return (
    <div className='page typo'>
      <currentUserContext.Provider value={currentUser}>
        <Header onSignOut={handleSingOut} />
        <Switch>
          <Route path='/signin'>
            <SignIn onSignInSubmit={handleSingIn} />
          </Route>
          <Route path='/signup'>
            <SignUp onSignUpSubmit={handleSingUp} />
          </Route>
          <ProtectedRoute
            path='/'
            component={Main}
            isLoggedIn={currentUser.isLoggedIn}
            cards={cards}
            onProfileEdit={openProfile}
            onAvatarEdit={openAvatar}
            onNewPlace={openPlace}
            onCardClick={openView}
            onCardLike={handleCardLike}
            onCardDrop={handleCardDropSubmit}
            onRefresh={reloadCards}
            isCardsLoading={isCardsLoading}
            cardsMessage={cardsSubstitutingMessage}
            isCardsError={isCardsLoadingError}
            isProfileLoading={isProfileLoading}
            profileMessage={profileSubstitutingMessage}
            isProfileError={isProfileLoadingError} />
        </Switch>
        <Footer />
        <EditProfilePopup
          isOpen={isProfileOpen}
          setOpenState={setProfileState}
          onProfileSubmit={handleProfileSubmit} />
        <EditAvatarPopup
          isOpen={isAvatarOpen}
          setOpenState={setAvatarState}
          onAvatarSubmit={handleAvatarSubmit} />
      </currentUserContext.Provider>
      <NewPlacePopup
        isOpen={isPlaceOpen}
        setOpenState={setPlaceState}
        onPlaceSubmit={handleNewPlaceSubmit} />

      <ImagePopup
        place={selectedCard.place}
        url={selectedCard.url}
        isOpen={isViewOpen}
        onClose={closeView} />
      <ErrorPopup
        isOpen={isErrorOpen}
        onErrorClose={handleErrorClose}
        errorObject={errorObject} />
      <SuccessPopup
        isOpen={isSuccessOpen}
        onSuccessClose={handleSuccessClose}
        message={successMessage} />
      <ConfirmDeletePopup
        isOpen={isConfirmDeleteOpen}
        onConfirmSubmit={handleCardDropConfirm}
        onConfirmClose={handleCardDropConfirmClose}
        isSaving={isDeleteInProgress}
        id={idDelCard}
        name={nameDelCard}
        link={linkDelCard} />
      <ConfirmNewPopup
        isOpen={isConfirmNewOpen}
        onConfirmSubmit={handleNewCardConfirm}
        onConfirmClose={handleNewCardConfirmClose}
        isSaving={isNewCardInProgress}
        name={nameNewCard}
        link={linkNewCard} />
      <ConfirmAvatarPopup
        isOpen={isConfirmAvatarOpen}
        onConfirmSubmit={handleAvatarConfirm}
        onConfirmClose={handleAvatarConfirmClose}
        isSaving={isUpdateAvatarInProgress}
        link={avatarUri} />
      <ConfirmProfilePopup
        isOpen={isConfirmProfileOpen}
        onConfirmSubmit={handleProfileConfirm}
        onConfirmClose={handleProfileConfirmClose}
        isSaving={isUpdateProfileInProgress}
        name={profileName}
        about={profileAbout} />
    </div>
  );
}

export default App;
