import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import currentUserContext from '../contexts/currentUserContext';

/**
 * @param root0
 * @param root0.isOpen
 * @param root0.setOpenState
 * @param root0.onProfileSubmit
 */
function EditProfilePopup({ isOpen, setOpenState, onProfileSubmit }) {
  const user = useContext(currentUserContext);
  const [userName, setUserName] = useState('');
  const [userTitle, setUserTitle] = useState('');
  const [isNameValid, setNameValidity] = useState(true);
  const [isTitleValid, setTitleValidity] = useState(true);
  const disabledClass = !(isNameValid && isTitleValid)
    ? 'popup__submit_disabled'
    : '';
  const openModifier = isOpen ? 'popup_opened' : '';

  const nameError = useRef();
  const titleError = useRef();
  const firstInput = useRef();

  useEffect(() => {
    if (isOpen) {
      setUserName(user.name);
      setUserTitle(user.title);
      firstInput.current.focus();
    }
  }, [isOpen, user.name, user.title]);

  React.useEffect(() => {
    /**
     * @param event
     */
    function handleEscapeClose(event) {
      if (event.key === 'Escape') {
        setOpenState(false);
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeClose);
    } else {
      document.removeEventListener('keydown', handleEscapeClose);
    }
  }, [isOpen, setOpenState]);

  /**
   * @param event
   */
  function handleNameChange(event) {
    const input = event.target;
    setUserName(input.value);
    setNameValidity(input.validity.valid);
    if (!input.validity.valid) {
      nameError.current.textContent = input.validationMessage;
    } else {
      nameError.current.textContent = '';
    }
  }
  /**
   * @param event
   */
  function handleTitleChange(event) {
    const input = event.target;
    setUserTitle(input.value);
    setTitleValidity(input.validity.valid);
    if (!input.validity.valid) {
      titleError.current.textContent = input.validationMessage;
    } else {
      titleError.current.textContent = '';
    }
  }

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onProfileSubmit(userName, userTitle);
  }

  /**
   * @param event
   */
  function handleClose() {
    setOpenState(false);
  }

  /**
   * @param event
   */
  const handleOverlayClose = (event) => {
    if (event.target === event.currentTarget && isOpen) {
      handleClose();
    }
  };
  return (
    <div
      className={`popup popup_content_profile ${openModifier}`}
      onMouseDown={handleOverlayClose}
      aria-hidden='true'>
      <form
        name='ProfileForm'
        className='popup__container popup__container_content_form'>
        <h2 className='popup__heading'>Редактировать профиль</h2>
        <fieldset className='popup__inputs'>
          <input
            id='name-input'
            name='nameInput'
            type='text'
            className='popup__input popup__input_name'
            required
            minLength='2'
            maxLength='40'
            value={userName}
            onChange={handleNameChange}
            ref={firstInput} />
          <span
            ref={nameError}
            id='name-input-error'
            className='popup__error-message' />
          <input
            id='title-input'
            name='titleInput'
            type='text'
            className='popup__input popup__input_title'
            required
            minLength='2'
            maxLength='200'
            value={userTitle}
            onChange={handleTitleChange} />
          <span
            ref={titleError}
            id='title-input-error'
            className='popup__error-message' />
        </fieldset>
        <button
          type='submit'
          className={`popup__submit popup__submit_profile ${disabledClass}`}
          onClick={handleSubmit}
          disabled={!(isNameValid && isTitleValid)}>
          Сохранить
        </button>
        <button
          type='button'
          className='popup__close popup__close_profile'
          onClick={handleClose}
          aria-label='Закрыть диалог редактирования профиля' />
      </form>
    </div>
  );
}

EditProfilePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
  onProfileSubmit: PropTypes.func.isRequired,
};

export default EditProfilePopup;
