import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import '../blocks/tile/tile.css';
import currentUserContext from '../contexts/currentUserContext';

/**
 *
 * @param root0
 * @param root0.cardItem
 * @param root0.onCardClick
 * @param root0.onCardLike
 * @param root0.onCardDrop
 */
function Card({
  cardItem, onCardClick, onCardLike, onCardDrop,
}) {
  const user = useContext(currentUserContext);

  const {
    id, ownerID, place, url, likes,
  } = cardItem;
  const isOwn = user.id === ownerID;
  /**
   *
   */
  const isLiked = () => {
    const result = likes.includes(user.id);
    return result;
  };
  /**
   *
   */
  function likeModifier() {
    if (isLiked()) {
      return 'tile__like_active';
    }
    return '';
  }
  /**
   *
   */
  function handleCardClick() {
    onCardClick(cardItem);
  }
  /**
   *
   */
  function handleLikeClick() {
    onCardLike(id, !isLiked());
  }
  /**
   *
   */
  function handleDeleteClick() {
    onCardDrop(id, place, url);
  }
  /**
   * @param evt
   */
  function handleKeyPressed(evt) {
    if (evt.keyCode === 'Enter' || evt.keyCode === ' ') {
      onCardClick(cardItem);
    }
  }
  return (
    <li className='tile'>
      <button
        className='tile__button'
        type='button'
        onClick={handleCardClick}
        onKeyDown={handleKeyPressed}
        aria-label='Открыть картинку'>
        <img src={`${url}`} alt={`${place}`} className='tile__image' />
      </button>
      {isOwn && (
        <button
          type='button'
          onClick={handleDeleteClick}
          className='tile__basket'
          aria-label='Удалить карточку' />
      )}
      <div className='tile__caption'>
        <h2 className='tile__name'>{`${place}`}</h2>
        <div className='tile__like-box'>
          <button
            type='button'
            className={`tile__like ${likeModifier()}`}
            onClick={handleLikeClick}
            aria-label={isLiked() ? 'Снять лайк' : 'Поставить лайк'} />
          <p className='tile__likes-count'>{likes.length}</p>
        </div>
      </div>
    </li>
  );
}
export default Card;

Card.propTypes = {
  cardItem: PropTypes.object.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onCardLike: PropTypes.func.isRequired,
  onCardDrop: PropTypes.func.isRequired,
};
