import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/**
 * @param root0
 * @param root0.isOpen
 * @param root0.setOpenState
 * @param root0.onPlaceSubmit
 */
function NewPlacePopup({ isOpen, setOpenState, onPlaceSubmit }) {
  const [place, setPlace] = useState('');
  const [url, setURL] = useState('');
  const [isPlaceValid, setPlaceValidity] = useState(true);
  const [isURLValid, setURLValidity] = useState(true);
  const disabledClass = !(isPlaceValid && isURLValid)
    ? 'popup__submit_disabled'
    : '';
  const openModifier = isOpen ? 'popup_opened' : '';
  const placeError = useRef();
  const urlError = useRef();
  useEffect(() => {
    if (isOpen) {
      setPlace('');
      setURL('');
      setPlaceValidity(true);
      setURLValidity(true);
    }
  }, [isOpen]);
  React.useEffect(() => {
    /**
     * @param event
     */
    function handleEscapeClose(event) {
      if (event.key === 'Escape') {
        setOpenState(false);
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeClose);
    } else {
      document.removeEventListener('keydown', handleEscapeClose);
    }
  }, [isOpen, setOpenState]);
  /**
   * @param event
   */
  function handlePlaceChange(event) {
    const input = event.target;
    setPlace(input.value);
    setPlaceValidity(input.validity.valid);
    if (!input.validity.valid) {
      placeError.current.textContent = input.validationMessage;
    } else {
      placeError.current.textContent = '';
    }
  }
  /**
   * @param event
   */
  function handleURLChange(event) {
    const input = event.target;
    setURL(input.value);
    setURLValidity(input.validity.valid);
    if (!input.validity.valid) {
      urlError.current.textContent = input.validationMessage;
    } else {
      urlError.current.textContent = '';
    }
  }

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onPlaceSubmit(place, url);
  }

  /**
   * @param event
   */
  function handleClose() {
    setOpenState(false);
  }

  const handleOverlayClose = (event) => {
    if (event.target === event.currentTarget && isOpen) {
      handleClose();
    }
  };

  return (
    <div
      className={`popup popup_content_place ${openModifier}`}
      onMouseDown={handleOverlayClose}
      aria-hidden='true'>
      <form
        name='placeForm'
        className='popup__container popup__container_content_form'>
        <h2 className='popup__heading'>Новое место</h2>
        <fieldset className='popup__inputs'>
          <input
            id='place-input'
            name='placeInput'
            type='text'
            className='popup__input popup__input_place'
            required
            minLength='2'
            maxLength='70'
            value={place}
            onChange={handlePlaceChange}
            placeholder='Название' />
          <span
            ref={placeError}
            id='name-input-error'
            className='popup__error-message' />
          <input
            id='url-input'
            name='urlInput'
            type='text'
            className='popup__input popup__input_url'
            required
            minLength='2'
            maxLength='200'
            value={url}
            onChange={handleURLChange}
            placeholder='Ссылка на картинку' />
          <span
            ref={urlError}
            id='title-input-error'
            className='popup__error-message' />
        </fieldset>
        <button
          type='submit'
          className={`popup__submit popup__submit_profile ${disabledClass}`}
          onClick={handleSubmit}
          disabled={!(isPlaceValid && isURLValid)}>
          Сохранить
        </button>
        <button
          type='button'
          className='popup__close popup__close_profile'
          onClick={handleClose}
          aria-label='Закрыть диалог добавления новой карточки' />
      </form>
    </div>
  );
}

NewPlacePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
  onPlaceSubmit: PropTypes.func.isRequired,
};

export default NewPlacePopup;
