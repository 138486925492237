import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param root0
 * @param root0.isOpen
 * @param root0.onConfirmSubmit
 * @param root0.onConfirmClose
 * @param root0.isSaving
 * @param root0.id
 * @param root0.name
 * @param root0.link
 */
function ConfirmProfilePopup({
  isOpen,
  onConfirmSubmit,
  onConfirmClose,
  isSaving,
  name,
  about,
}) {
  const buttonText = isSaving ? 'Изменяю...' : 'Да!';
  const openModifier = isOpen ? 'popup_opened' : '';

  React.useEffect(() => {
    /**
     * @param event
     */
    function handleEscapeClose(event) {
      if (event.key === 'Escape') {
        onConfirmClose();
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeClose);
    } else {
      document.removeEventListener('keydown', handleEscapeClose);
    }
  }, [isOpen, onConfirmClose]);

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onConfirmSubmit(name, about);
  }

  /**
   * @param event
   */
  function handleClose() {
    onConfirmClose();
  }
  const handleOverlayClose = (event) => {
    if (event.target === event.currentTarget && isOpen) {
      onConfirmClose();
    }
  };
  return (
    <div
      className={`popup popup_content_confirm ${openModifier}`}
      onMouseDown={handleOverlayClose}
      aria-hidden='true'>
      <form
        name='ConfirmForm'
        className='popup__container popup__container_content_confirm'
        aria-label='Диалог подтверждения изменения профиля пользователя'>
        <h2 className='popup__heading'>Вы уверены, что хотите установить такой профиль?</h2>
        <div className='popup__card'>
          <div className='popup__line'>
            <h3 className='popup__title'>Имя:</h3>
            <p className='popup__text'>{`${name}`}</p>
          </div>
          <div className='popup__line'>
            <h3 className='popup__title'>Описание:</h3>
            <p className='popup__text'>{`${about}`}</p>
          </div>
        </div>

        <button type='submit' className='popup__submit' onClick={handleSubmit}>
          {buttonText}
        </button>
        <button
          type='button'
          className='popup__close popup__close_profile'
          onClick={handleClose}
          aria-label='Закрыть диалог подтверждения' />
      </form>
    </div>
  );
}

ConfirmProfilePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirmClose: PropTypes.func.isRequired,
  onConfirmSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
};

export default ConfirmProfilePopup;
