import '../blocks/photo-grid/photo-grid.css';
import React from 'react';
import PropTypes from 'prop-types';

import Card from './Card';

/**
 * @param root0
 * @param root0.cards
 * @param root0.onCardClick
 * @param root0.onCardLike
 * @param root0.onCardDrop
 * @param root0.isLoading
 * @param root0.isError
 * @param root0.message
 */
const PhotoGrid = ({
  cards,
  onCardClick,
  onCardLike,
  onCardDrop,

}) => (
  <ul className='photogrid'>
    {cards.map((card) => (
      <Card
        key={card.id}
        cardItem={card}
        onCardClick={onCardClick}
        onCardLike={onCardLike}
        onCardDrop={onCardDrop} />
    ))}
  </ul>
);
PhotoGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCardClick: PropTypes.func.isRequired,
  onCardLike: PropTypes.func.isRequired,
  onCardDrop: PropTypes.func.isRequired,

};

export default PhotoGrid;
