import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import currentUserContext from '../contexts/currentUserContext';

/**
 * @param root0
 * @param root0.isOpen
 * @param root0.setOpenState
 * @param root0.onAvatarSubmit
 */
function EditAvatarPopup({ isOpen, setOpenState, onAvatarSubmit }) {
  const user = useContext(currentUserContext);
  const [userAvatar, setUserAvatar] = useState('');
  const [isAvatarValid, setAvatarValidity] = useState(true);
  const disabledClass = !isAvatarValid ? 'popup__submit_disabled' : '';
  const openModifier = isOpen ? 'popup_opened' : '';
  const urlInput = useRef();
  const urlError = useRef();
  useEffect(() => {
    if (isOpen) {
      setUserAvatar(user.avatar);
    }
  }, [isOpen, user.avatar]);

  React.useEffect(() => {
    /**
     * @param event
     */
    function handleEscapeClose(event) {
      if (event.key === 'Escape') {
        setOpenState(false);
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeClose);
    } else {
      document.removeEventListener('keydown', handleEscapeClose);
    }
  }, [isOpen, setOpenState]);

  /**
   * @param event
   */
  function handleURLChange(event) {
    const input = event.target;
    setUserAvatar(input.value);
    setAvatarValidity(input.validity.valid);
    if (!input.validity.valid) {
      urlError.current.textContent = input.validationMessage;
    } else {
      urlError.current.textContent = '';
    }
  }

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onAvatarSubmit(urlInput.current.value);
  }

  /**
   * @param event
   */
  function handleClose() {
    setOpenState(false);
  }
  const handleOverlayClose = (event) => {
    if (event.target === event.currentTarget && isOpen) {
      handleClose();
    }
  };
  return (
    <div
      className={`popup popup_content_avatar ${openModifier}`}
      onMouseDown={handleOverlayClose}
      aria-hidden='true'>
      <form
        name='ProfileForm'
        className='popup__container popup__container_content_form'>
        <h2 className='popup__heading'>Обновить аватар</h2>
        <fieldset className='popup__inputs'>
          <input
            id='avatar-input'
            name='avatarInput'
            type='url'
            className='popup__input popup__input_avatar'
            placeholder='Ссылка на аватар'
            required
            minLength='2'
            maxLength='200'
            value={userAvatar}
            onChange={handleURLChange}
            ref={urlInput} />
          <span
            ref={urlError}
            id='name-input-error'
            className='popup__error-message' />
        </fieldset>
        <button
          type='submit'
          className={`popup__submit popup__submit_profile ${disabledClass}`}
          onClick={handleSubmit}
          disabled={!isAvatarValid}>
          Сохранить
        </button>
        <button
          type='button'
          className='popup__close popup__close_profile'
          onClick={handleClose}
          aria-label='Закрыть диалог редактирования аватара' />
      </form>
    </div>
  );
}

EditAvatarPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
  onAvatarSubmit: PropTypes.func.isRequired,
};

export default EditAvatarPopup;
