import React, {
  useState, useEffect, useContext,
} from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import currentUserContext from '../contexts/currentUserContext';

function SignUp({ onSignUpSubmit }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [isConfirmValid, setConfirmValidity] = useState(true);
  const [isEmailValid, setEmailValidity] = useState(true);
  const [isPasswordValid, setPasswordValidity] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [isFormValid, setFormValidityState] = useState(false);

  const emailInputErrorClass = !isEmailValid ? 'credentials__input_invalid' : '';
  const passwordInputErrorClass = !isPasswordValid ? 'credentials__input_invalid' : '';
  const confirmInputErrorClass = !isConfirmValid ? 'credentials__input_invalid' : '';
  const user = useContext(currentUserContext);
  const { isLoggedIn } = user;

  useEffect(() => {
    if (isLoggedIn) {
      setEmail('');
      setPassword('');
      setConfirm('');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setConfirmValidity(password === confirm);
  }, [password, confirm]);

  useEffect(() => {
    if (isConfirmValid) {
      setConfirmError('');
    } else { setConfirmError('Пароли не совпадают!'); }
  }, [isConfirmValid]);

  useEffect(() => {
    setFormValidityState(isEmailValid
    && isPasswordValid
    && isConfirmValid
    && email.length > 0
    && password.length > 0
    && confirm.length > 0);
  }, [isEmailValid, isPasswordValid, isConfirmValid, email, password, confirm]);

  function handleEmailChange(event) {
    const input = event.target;
    setEmail(input.value);
    setEmailValidity(input.validity.valid);
    if (isEmailValid) {
      setEmailError('');
    } else {
      setEmailError(input.validationMessage);
    }
  }

  function handlePasswordChange(event) {
    const input = event.target;
    setPassword(input.value);
    setPasswordValidity(input.validity.valid);
    if (isPasswordValid) {
      setPasswordError('');
    } else {
      setPasswordError(input.validationMessage);
    }
  }

  function handleConfirmChange(event) {
    const input = event.target;
    setConfirm(input.value);
  }

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onSignUpSubmit(email, password);
  }

  return (
    <main className='credentials' aria-hidden='true'>
      <form
        name='SignInForm'
        className='credentials__form'
        aria-label='Форма регистрации в системе'>
        <div className='credentials__container'>
          <h2 className='credentials__heading'>Регистрация</h2>
          <fieldset className='credentials__inputs'>
            <input
              id='signup-email-input'
              name='email'
              type='email'
              className={`credentials__input ${emailInputErrorClass}`}
              required
              minLength='2'
              maxLength='200'
              value={email}
              onChange={handleEmailChange}
              placeholder='E-mail'
              aria-label='Поле ввода адреса электронной почты' />
            <span
              id='signin-email-input-error'
              className='credentials__error-message'>
              {emailError}
            </span>
            <input
              id='signup-password-input'
              name='password'
              type='password'
              className={`credentials__input ${passwordInputErrorClass}`}
              required
              minLength='2'
              maxLength='200'
              value={password}
              onChange={handlePasswordChange}
              placeholder='Пароль'
              aria-label='Поле ввода пароля' />
            <span
              id='signin-password-input-error'
              className='credentials__error-message'>
              {passwordError}
            </span>
            <input
              id='signup-confirm-input'
              name='confirm'
              type='password'
              className={`credentials__input ${confirmInputErrorClass}`}
              required
              minLength='2'
              maxLength='200'
              value={confirm}
              onChange={handleConfirmChange}
              placeholder='Повтор пароля'
              aria-label='Поле повторного ввода пароля для проверки правильности ввода' />
            <span
              id='signin-confirm-input-error'
              className='credentials__error-message'>
              {confirmError}
            </span>
          </fieldset>
        </div>
        <button
          type='submit'
          className='credentials__submit'
          onClick={handleSubmit}
          disabled={!isFormValid}
          aria-label='Кнопка регистрации'>
          Зарегистрироваться
        </button>
        <Link to='/signin' className='credentials__link'>Уже зарегистрированы? Войти</Link>
      </form>
    </main>
  );
}
SignUp.propTypes = {
  onSignUpSubmit: PropTypes.func.isRequired,

};
export default SignUp;
