import React from 'react';
import PropTypes from 'prop-types';
import Lead from './Lead';
import PhotoGrid from './PhotoGrid';

/**
 * @param root0
 * @param root0.cards
 * @param root0.onProfileEdit
 * @param root0.onAvatarEdit
 * @param root0.onNewPlace
 * @param root0.onCardClick
 * @param root0.onCardLike
 * @param root0.onCardDrop
 * @param root0.onRefresh
 * @param root0.isLoading
 * @param root0.message
 * @param root0.isError
 */
const Main = ({
  cards,
  onProfileEdit,
  onAvatarEdit,
  onNewPlace,
  onCardClick,
  onCardLike,
  onCardDrop,
  onRefresh,
  isCardsLoading,
  cardsMessage,
  isCardsError,
  isProfileLoading,
  profileMessage,
  isProfileError,
}) => (
  <main className='wrapper'>
    {isProfileLoading || isProfileError ? (
      <div style={{ width: '100%' }}>
        <p style={{ fontSize: '36px' }}>{profileMessage}</p>
      </div>
    ) : (
      <Lead
        onProfileEdit={onProfileEdit}
        onAvatarEdit={onAvatarEdit}
        onNewPlace={onNewPlace}
        onRefresh={onRefresh} />
    )}
    {isCardsLoading || isCardsError ? (
      <div style={{ width: '100%' }}>
        <p style={{ fontSize: '36px' }}>{cardsMessage}</p>
      </div>
    ) : (
      <PhotoGrid
        cards={cards}
        onCardClick={onCardClick}
        onCardLike={onCardLike}
        onCardDrop={onCardDrop} />
    )}
  </main>
);

Main.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProfileEdit: PropTypes.func.isRequired,
  onAvatarEdit: PropTypes.func.isRequired,
  onNewPlace: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onCardLike: PropTypes.func.isRequired,
  onCardDrop: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  isCardsLoading: PropTypes.bool.isRequired,
  isCardsError: PropTypes.bool.isRequired,
  cardsMessage: PropTypes.string.isRequired,
  isProfileLoading: PropTypes.bool.isRequired,
  isProfileError: PropTypes.bool.isRequired,
  profileMessage: PropTypes.string.isRequired,
};
export default Main;
