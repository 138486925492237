import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param root0
 * @param root0.isOpen
 * @param root0.onConfirmSubmit
 * @param root0.onConfirmClose
 * @param root0.isSaving
 * @param root0.id
 * @param root0.name
 * @param root0.link
 */
function ConfirmDeletePopup({
  isOpen,
  onConfirmSubmit,
  onConfirmClose,
  isSaving,
  id,
  name,
  link,
}) {
  const buttonText = isSaving ? 'Удаляю...' : 'Да!';
  const openModifier = isOpen ? 'popup_opened' : '';

  React.useEffect(() => {
    /**
     * @param event
     */
    function handleEscapeClose(event) {
      if (event.key === 'Escape') {
        onConfirmClose();
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeClose);
    } else {
      document.removeEventListener('keydown', handleEscapeClose);
    }
  }, [isOpen, onConfirmClose]);

  /**
   * @param event
   */
  function handleSubmit(event) {
    event.preventDefault();
    onConfirmSubmit(id);
  }

  /**
   * @param event
   */
  function handleClose() {
    onConfirmClose();
  }
  const handleOverlayClose = (event) => {
    if (event.target === event.currentTarget && isOpen) {
      onConfirmClose();
    }
  };
  return (
    <div
      className={`popup popup_content_confirm ${openModifier}`}
      onMouseDown={handleOverlayClose}
      aria-hidden='true'>
      <form
        name='ConfirmForm'
        className='popup__container popup__container_content_confirm'
        aria-label={`Диалог подтверждения удаления карточки ${name}`}>
        <h2 className='popup__heading'>Вы уверены, что хотите удалить эту карточку?</h2>
        <div className='popup__card'>
          <img src={`${link}`} alt={`${name}`} className='popup__img' />
          <h3 className='popup__caption'>{`${name}`}</h3>
        </div>

        <button type='submit' className='popup__submit' onClick={handleSubmit}>
          {buttonText}
        </button>
        <button
          type='button'
          className='popup__close popup__close_profile'
          onClick={handleClose}
          aria-label='Закрыть диалог подтверждения' />
      </form>
    </div>
  );
}

ConfirmDeletePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirmClose: PropTypes.func.isRequired,
  onConfirmSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ConfirmDeletePopup;
