import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import '../blocks/lead/lead.css';
import '../blocks/profile/profile.css';
import currentUserContext from '../contexts/currentUserContext';

/**
 * @param root0
 * @param root0.onProfileEdit
 * @param root0.onAvatarEdit
 * @param root0.onNewPlace
 * @param root0.onRefresh
 */
function Lead({
  onProfileEdit, onAvatarEdit, onNewPlace, onRefresh,
}) {
  const { name, title, avatar } = useContext(currentUserContext);
  /**
   *
   */
  function handleRefresh() {
    onRefresh();
  }
  return (
    <section className='lead'>
      <div className='profile'>
        <button
          type='button'
          id='editAvatarButton'
          className='profile__avatar'
          onClick={onAvatarEdit}
          style={{
            backgroundImage: `url(${avatar})`,
          }}
          aria-label='Изменить аватар' />
        <div className='profile__heading'>
          <div className='profile__name-container'>
            <h1 className='profile__name'>{name}</h1>
            <button
              id='editProfileButton'
              type='button'
              className='profile__edit'
              onClick={onProfileEdit}
              aria-label='Изменить профиль' />
          </div>
          <p className='profile__title'>{title}</p>
        </div>
        <div className='profile__buttons'>
          <button
            type='button'
            id='newPlaceButton'
            name='addTileButton'
            className='profile__add-place'
            onClick={onNewPlace}
            aria-label='Добавить карточку' />
          <button
            id='refreshCardsButton'
            type='button'
            className='profile__refresh'
            onClick={handleRefresh}
            aria-label='Обновить карточки с сервера' />
        </div>
      </div>
    </section>
  );
}

Lead.propTypes = {
  onProfileEdit: PropTypes.func.isRequired,
  onAvatarEdit: PropTypes.func.isRequired,
  onNewPlace: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default Lead;
