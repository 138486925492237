import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import '../blocks/header/header.css';
import { ReactComponent as Logo } from '../img/logo-white.svg';

import HeaderIcon from './HeaderIcon';
import HeaderHamburger from './HeaderHamburger';

import currentUserContext from '../contexts/currentUserContext';

/**
 *
 */
function Header({ onSignOut }) {
  const userContext = useContext(currentUserContext);
  const { email, isLoggedIn } = userContext;
  const location = useLocation();
  const [isHamburgerOpen, setHamburgerState] = React.useState(false);

  const linkRoutes = {
    '/signup': '/signin',
    '/signin': '/signup',
    '/': '/',
  };

  const linkNames = {
    '/signup': 'Регистрация',
    '/signin': 'Войти',
    '/': ' ',
  };

  function handleHamburgerClick() {
    setHamburgerState(() => !isHamburgerOpen);
  }
  return (
    <header className='header'>
      {(isHamburgerOpen && isLoggedIn) && <HeaderHamburger email={email} onSignOut={onSignOut} />}
      <div className='header__wrapper'>
        <Logo className='header__logo' alt='Логотип проекта' />
        {isLoggedIn
          ? (
            <div className='header__container'>
              <div className='header__menu'>
                <p className='header__email' aria-label='Электронная почта текущего пользователя'>{email}</p>
                <button
                  className='header__button'
                  type='button'
                  onClick={onSignOut}
                  aria-label='Выйти из системы'>
                  Выйти
                </button>
              </div>
              <HeaderIcon
                isOpen={isHamburgerOpen}
                onClick={handleHamburgerClick} />
            </div>
          )
          : (
            <Link
              className='header__link'
              to={linkRoutes[location.pathname]}>
              {linkNames[linkRoutes[location.pathname]]}
            </Link>
          )}
      </div>

    </header>
  );
}

Header.propTypes = {
  onSignOut: PropTypes.func.isRequired,
};

export default Header;
