import React from 'react';
import '../blocks/footer/footer.css';

/**
 *
 */
const Footer = () => (
  <footer className='footer'>
    <p className='footer__copyright'>&copy; 2021 Mesto Russia</p>
  </footer>
);

export default Footer;
