import React, { useCallback } from 'react';

import { PropTypes } from 'prop-types';

/**
 * @param root0
 * @param root0.isOpen
 * @param root0.place
 * @param root0.url
 * @param root0.onClose
 * @param root0.setOpenState
 */
function ImagePopup({
  isOpen, place, url, onClose,
}) {
  const activeModifier = isOpen ? 'popup_opened' : '';
  /**
   *
   */
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOverlayClose = (event) => {
    if (event.target === event.currentTarget && isOpen) {
      handleClose();
    }
  };
  React.useEffect(() => {
    /**
     * @param event
     */
    function handleEscapeClose(event) {
      if (event.key === 'Escape') {
        handleClose();
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeClose);
    } else {
      document.removeEventListener('keydown', handleEscapeClose);
    }
  }, [isOpen, handleClose]);

  return (
    <div
      className={`popup popup_content_view ${activeModifier}`}
      onMouseDown={handleOverlayClose}
      aria-hidden='true'>
      <figure className='popup__container popup__container_content_image'>
        <img className='popup__image' src={`${url}`} alt={`${place}`} />
        <figcaption className='popup__figcaption'>{place}</figcaption>
        <button
          type='button'
          className='popup__close popup__close_view'
          onClick={handleClose}
          aria-label='Закрыть полноразмерную картинку' />
      </figure>
    </div>
  );
}
ImagePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  place: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default ImagePopup;
