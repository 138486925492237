import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../img/header-close.svg';
import { ReactComponent as OpenIcon } from '../img/header-icon.svg';

const HeaderIcon = ({ isOpen, onClick }) => (
  <div className='header__icon'>
    {isOpen
      ? <CloseIcon className='header__close' onClick={onClick} aria-label='Свернуть верхнее меню' />
      : <OpenIcon className='header__open' onClick={onClick} aria-label='Раскрыть верхнее меню' />}
  </div>
);

HeaderIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeaderIcon;
